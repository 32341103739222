export default function extractIds(url) {
  const urlParts = url.split('/');
  const studyIndex = urlParts.indexOf('studies');
  const seriesIndex = urlParts.indexOf('series');
  const instancesIndex = urlParts.indexOf('instances');

  if (studyIndex === -1 || seriesIndex === -1 || instancesIndex === -1) {
    return null;
  }

  const studyId = urlParts[studyIndex + 1];
  const seriesId = urlParts[seriesIndex + 1];
  const instanceId = urlParts[instancesIndex + 1];

  return {
    studyId: studyId,
    seriesId: seriesId,
    instanceId: instanceId,
  };
}
