import { useEffect } from 'react';
import * as cornerstoneTools from '@cornerstonejs/tools';
import { Types as CornerstoneType } from '@cornerstonejs/core';

const useAnnotationMessaging = (
  appConfig,
  viewportGridService,
  cornerstoneViewportService,
  measurementService,
  toolbarService,
  DicomMetadataStore
) => {
  useEffect(() => {
    const childResponse = (event: MessageEvent<string>) => {
      if (event.origin !== appConfig.mediq.postMsgUrl) {
        // console.log("wrong");
        return;
      }

      const command = JSON.parse(event.data).command;
      console.log(command);

      if (command === 'show annotation') {
        const annotations = JSON.parse(event.data).annotations;

        console.log(annotations);

        const viewportId = viewportGridService.getActiveViewportId();
        const renderingEngine = cornerstoneViewportService.getRenderingEngine();
        const viewport = renderingEngine.getViewport(viewportId);
        const { element } = cornerstoneViewportService.getCornerstoneViewport(viewportId);

        annotations.forEach(annotation => {
          const referencedImageId = `wadors:${appConfig.dataSources[0].configuration.wadoUriRoot}/${annotation.imageId}`;
          const key = `imageId:${referencedImageId}`;

          const data = {
            metadata: {
              toolName: 'Length',
              // viewPlaneNormal: [1, 0, 0] as CornerstoneType.Point3,
              // viewUp: [0, 0, 1] as CornerstoneType.Point3,
              FrameOfReferenceUID: undefined,
              referencedImageId,
            },
            // referenceSeriesUID: '1.3.6.1.4.1.5962.99.1.39670298.891180625.1666487013914.5.0',
            // referenceStudyUID: '1.3.6.1.4.1.5962.99.1.39670298.891180625.1666487013914.4.0',
            // frameNumber: 1,
            // toolName: 'Length',
            // displaySetInstanceUID: '4b0e12ae-b6c8-39c1-3d0b-d26f4b1e5de6',
            // label: '',
            // displayText: ['149 px (S: 1 I: 1)'],
            annotationUID: annotation.annotationUID,
            data: {
              handles: {
                points: annotation.points as CornerstoneType.Point3[],
                activeHandleIndex: null,
                textBox: {
                  hasMoved: false,
                  worldPosition: [0, 0, 0] as CornerstoneType.Point3,
                  worldBoundingBox: {
                    topLeft: [0, 0, 0] as CornerstoneType.Point3,
                    topRight: [0, 0, 0] as CornerstoneType.Point3,
                    bottomLeft: [0, 0, 0] as CornerstoneType.Point3,
                    bottomRight: [0, 0, 0] as CornerstoneType.Point3,
                  },
                },
              },
              label: '',
              cachedStats: {
                [key]: {
                  length: annotation.length,
                  unit: annotation.unit,
                },
              },
            },
          };

          cornerstoneTools.annotation.state.addAnnotation(data, element);
        });
        viewport?.render();
      } else if (command === 'delete annotation') {
        const annotationIds = cornerstoneTools.annotation.selection.getAnnotationsSelected();
        if (annotationIds.length > 0) {
          const selectedAnnotation = cornerstoneTools.annotation.state.getAnnotation(
            annotationIds[0]
          );

          const args = JSON.parse(event.data).args;
          if (selectedAnnotation) {
            if (args?.[0] !== 'partial delete') {
              window.parent.postMessage(
                JSON.stringify({ command: 'delete annotation', annotation: selectedAnnotation }),
                appConfig.mediq.postMsgUrl
              );
            }

            cornerstoneTools.annotation.state.removeAnnotation(annotationIds[0]);
            console.log(selectedAnnotation);
          }
        }
      } else if (command === 'activate annotation') {
        toolbarService.recordInteraction({
          groupId: 'MeasurementTools',
          itemId: 'MeasurementTools',
          interactionType: 'tool',
          commands: [
            {
              commandName: 'setToolActive',
              commandOptions: {
                toolName: 'Length',
              },
              context: 'CORNERSTONE',
            },
            {
              commandName: 'setToolActive',
              commandOptions: {
                toolName: 'SRLength',
                toolGroupId: 'SRToolGroup',
              },
              // we can use the setToolActive command for this from Cornerstone commandsModule
              context: 'CORNERSTONE',
            },
          ],
        });
      } else if (command === 'deActivate annotation') {
        toolbarService.recordInteraction({
          groupId: 'WindowLevel',
          interactionType: 'tool',
          commands: [
            {
              commandName: 'setToolActive',
              commandOptions: {
                toolName: 'WindowLevel',
              },
              context: 'CORNERSTONE',
            },
          ],
        });
      }
    };
    window.addEventListener('message', childResponse);

    const { MEASUREMENT_ADDED, MEASUREMENT_UPDATED, MEASUREMENT_SELECTED } =
      measurementService.EVENTS;
    measurementService.subscribe(MEASUREMENT_ADDED, ({ source, measurement }) => {
      const { referenceSeriesUID, referenceStudyUID, SOPInstanceUID } = measurement;

      const instance = DicomMetadataStore.getInstance(
        referenceStudyUID,
        referenceSeriesUID,
        SOPInstanceUID
      );

      window.parent.postMessage(
        JSON.stringify({
          command: 'add annotation',
          annotation: measurement,
          regions: instance['SequenceOfUltrasoundRegions'],
        }),
        appConfig.mediq.postMsgUrl
      );
    });

    measurementService.subscribe(MEASUREMENT_UPDATED, ({ source, measurement }) => {
      const { referenceSeriesUID, referenceStudyUID, SOPInstanceUID } = measurement;

      const instance = DicomMetadataStore.getInstance(
        referenceStudyUID,
        referenceSeriesUID,
        SOPInstanceUID
      );

      window.parent.postMessage(
        JSON.stringify({
          command: 'update annotation',
          annotation: measurement,
          regions: instance['SequenceOfUltrasoundRegions'],
        }),
        appConfig.mediq.postMsgUrl
      );
    });

    measurementService.subscribe(MEASUREMENT_SELECTED, ({ source, measurement }) => {
      window.parent.postMessage(
        JSON.stringify({
          command: 'selected annotation',
          annotation: measurement,
        }),
        appConfig.mediq.postMsgUrl
      );
    });

    cornerstoneViewportService.subscribe(
      cornerstoneViewportService.EVENTS.VIEWPORT_DATA_CHANGED,
      args => {
        window.parent.postMessage(
          JSON.stringify({
            command: 'grid ready',
          }),
          appConfig.mediq.postMsgUrl
        );
      }
    );

    return () => {
      window.removeEventListener('message', childResponse);
    };
  }, [
    appConfig,
    viewportGridService,
    cornerstoneViewportService,
    measurementService,
    toolbarService,
    DicomMetadataStore,
  ]);
};

export default useAnnotationMessaging;
