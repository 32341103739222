import { useEffect } from 'react';

const useArrowNavigation = (appConfig, commandsManager) => {
  useEffect(() => {
    const childResponse = (event: MessageEvent<string>) => {
      if (event.origin !== appConfig.mediq.postMsgUrl) {
        return;
      }

      const command = JSON.parse(event.data).command;

      if (command === 'next image') {
        commandsManager.runCommand('updateViewportDisplaySet', {
          direction: 1,
        });
      } else if (command === 'previous image') {
        commandsManager.runCommand('updateViewportDisplaySet', {
          direction: -1,
        });
      }
    };
    window.addEventListener('message', childResponse);

    return () => {
      window.removeEventListener('message', childResponse);
    };
  }, [appConfig, commandsManager]);
};

export default useArrowNavigation;
